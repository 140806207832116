<template>
  <ul class="word_list flex">
    <li @click="routePush(item.id)" v-if="$route.meta != item.title" v-for="(item, index) in list" :key="index">
      <img :src="item.imgUrl" alt="">
      <p>{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  mounted() {
  },
  data() {
    return {
      list: [
        {
          id: 0,
          imgUrl: require('@/assets/wordOp/0.png'),
          title: '个人简介',
          path: '/IndividualResume'
        },
        {
          id: 1,
          imgUrl: require('@/assets/wordOp/1.png'),
          title: '广告语',
          path: '/slogan'
        },
        {
          id: 2,
          imgUrl: require('@/assets/wordOp/2.png'),
          title: '文稿润色',
          path: '/manuscriptPolishing'
        },
        // {
        //   id: 3,
        //   imgUrl: require('@/assets/wordOp/4.png'),
        //   title: '错别字修改',
        //   path: '/wrongly',
        // },
        {
          id: 14,
          imgUrl: require('@/assets/wordOp/14.png'),
          title: '一点成诗',
          path: '/verse',
        },
        {
          id: 4,
          imgUrl: require('@/assets/wordOp/4.png'),
          title: '记叙文',
          path: '/narration',
        },
        {
          id: 5,
          imgUrl: require('@/assets/wordOp/5.png'),
          title: '产品简介',
          
          path: '/productResume',
        },
        {
          id: 6,
          imgUrl: require('@/assets/wordOp/6.png'),
          title: '短视频脚本',
          path: '/shortVideo',
        },
        {
          id: 7,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '议论文',
          path: '/esay',
        },
        {
          id: 8,
          imgUrl: require('@/assets/wordOp/8.png'),
          title: '祝福语',
          
          path: '/greeting'
        },
        {
          id: 9,
          imgUrl: require('@/assets/wordOp/9.png'),
          title: '文稿改写',
          path: '/manuscriptRewriting',
        },
        {
          id: 10,
          imgUrl: require('@/assets/wordOp/10.png'),
          title: '企业简介',
          
          path: '/EnterpriseProfile'
        },
        {
          id: 11,
          imgUrl: require('@/assets/wordOp/11.png'),
          title: '纪录片脚本',
          path: '/documentary',
        },
        {
          id: 12,
          imgUrl: require('@/assets/wordOp/2.png'),
          title: '标题提炼',
          path: '/titleExtraction',
        },
        {
          id: 15,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '营销软文',
          path: '/article',
        },
        {
          id: 16,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '说明书',
          path: '/instructions',
        },
        {
          id: 17,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: 'AI小说/歌词',
          path: '/novel',
        },
        {
          id: 18,
          imgUrl: require('@/assets/wordOp/18.png'),
          title: '商业计划书',
          path: '/business',
        },
        {
          id: 19,
          imgUrl: require('@/assets/wordOp/19.png'),
          title: 'PPT内容框架',
          path: '/ppt',
        },
        {
          id: 20,
          imgUrl: require('@/assets/wordOp/20.png'),
          title: 'AI策划方案',
          path: '/activities',
        },
        {
          id: 21,
          imgUrl: require('@/assets/wordOp/21.png'),
          title: 'AI发言稿',
          path: '/speechdraft',
        },
        {
          id: 22,
          imgUrl: require('@/assets/wordOp/22.png'),
          title: 'AI演讲稿',
          path: '/speechtext',
        },
        {
          id: 23,
          imgUrl: require('@/assets/wordOp/23.png'),
          title: '小红书文案',
          path: '/word_redbook',
        },
      ],
      fullscreenLoading: false
    }
  },
  methods: {
    routePush(id) {
      this.list.forEach(item => {
        if (item.id == id) {
          return this.$router.push(item.path)
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
.word_list {
  // min-width: 500px;
  max-width: 410px;
  // max-height:550px;
  max-height: 550px;
  margin: 50px 0;
  //justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: rgb(62, 160, 254);
    width: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    width: 10px;
    border-radius: 10px;
  }

  li {
    margin: 0 1rem 1rem 0;
    width: 10rem;
    height: 10rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 1.25rem;
    border: 0.25rem solid rgba(255, 255, 255, 1);
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
      background: rgba(196, 227, 255, 1);
    }

    img {
      width: 4rem;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 1.2rem;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>